// src/components/CancellationRefundPolicy.js
import React from 'react';
import './CancellationRefundPolicy.css'; // Import the CSS file for styling
import Navbar from './Navbar';
import './CancellationRefundPolicy.css';

const CancellationRefundPolicy = () => {
  return (
    <div className="cancellation-refund-policy">
        <Navbar />
      <h1>Cancellation & Refund Policy</h1>
      <p>Last updated: [Insert Date]</p>
      <p>
        Thank you for using our services. We value your satisfaction and strive to ensure a seamless experience. This Cancellation & Refund Policy outlines the conditions under which refunds and cancellations will be processed.
      </p>

      <h2>Cancellation Policy</h2>
      <p>
        If you wish to cancel your subscription or order, you must notify us within 24 hours of the transaction. Please contact our support team at [support email/phone] with your order details to initiate the cancellation process.
      </p>

      <h2>Refund Policy</h2>
      <p>
        Refunds will only be issued under the following conditions:
      </p>
      <ul>
        <li>The service/product was not delivered as promised.</li>
        <li>There was an error in the billing or transaction process.</li>
        <li>The cancellation request is made within the allowed timeframe as per the Cancellation Policy.</li>
      </ul>
      <p>
        Refunds will be processed within 7-10 business days and will be credited to the original mode of payment.
      </p>

      <h2>Non-Refundable Cases</h2>
      <p>
        The following cases are not eligible for refunds:
      </p>
      <ul>
        <li>Services already rendered or completed.</li>
        <li>Delays or issues caused by third-party service providers.</li>
        <li>User dissatisfaction arising from reasons not covered under this policy.</li>
      </ul>

      <h2>How to Request a Cancellation or Refund</h2>
      <p>
        To request a cancellation or refund, please provide the following details:
      </p>
      <ul>
        <li>Your full name and contact details.</li>
        <li>Order or transaction ID.</li>
        <li>Reason for cancellation or refund request.</li>
      </ul>
      <p>
        Send your request to [support email/phone]. We will review your case and notify you of the decision within 5 business days.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Cancellation & Refund Policy, please contact us at [support email/phone].
      </p>
    </div>
  );
};

export default CancellationRefundPolicy;
