import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="top-area">
          <div className="block">
            <div className="mb-2">
              <a href="https://hirespoof.com/" className="inline-block" aria-label="Hirespoof">
                <svg className="w-8 h-8 fill-current text-blue-600" viewBox="0 0 32 32" xmlns="https://imgur.com/rD4nQ5C">
                  <path d="" />
                </svg>
              </a>
            </div>
          
            <div className="text-gray-400 description">
            <h2>MADE WITH 💓 FOR THE GREAT MINDS</h2>
            </div>
          </div>
          <div className="block">
            <h3 className="text-gray-200 font-medium mb-1">Products</h3>
            <ul>
              <li className="mb-1">
                <a href="/internships" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Internships</a>
              </li>
              <li className="mb-1">
                <a href="/fulltime-jobs" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Freshers Jobs</a>
              </li>
              <li className="mb-1">
                <a href="/fulltime-jobs" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Experienced Jobs</a>
              </li>
             {/*<li className="mb-1">
                <a href="https://hirespoof.com/jobs-list/?filter-orderby=random&paged=1" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">ATS Friendly Resume Builder</a>
              </li> */} 
            </ul>
          </div>
         <div className="block">
           <h3 className="text-gray-200 font-medium mb-1">For Candidates</h3>
            <ul>
              <li className="mb-1">
                <a href="/hirespoofbot" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">AI Job Bot</a>
              </li>
              <li className="mb-1">
                <a href="/refer-and-earn" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Refer & Earn</a>
              </li> 
              <li className="mb-1">
                <a href="/referral" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Take referral</a>
              </li> 
             {/*  <li className="mb-1">
                <a href="https://hirespoof.com/my-resume/" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">My Resume</a>
              </li>*/}
             {/*   <li className="mb-1">
                <a href="https://hirespoof.com/my-applied/" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">My Applied</a>
              </li>*/}
              {/*     <li className="mb-1">
                <a href="https://hirespoof.com/shortlist-jobs/" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Shortlist Jobs</a>
              </li>*/}
            </ul>
          </div>
          <div className="block">
            <h3 className="text-gray-200 font-medium mb-1">Company</h3>
            <ul>
              <li className="mb-1">
                <a href="/" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Home</a>
              </li>
              <li className="mb-1">
                <a href="/about-us" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">About Us</a>
              </li>
              <li className="mb-3">
                <a href="/shipping-policy" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Shipping Policy</a>
              </li>
              <li className="mb-1">
                <a href="/disclaimer" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Disclaimer</a>
              </li>
              <li className="mb-1">
                <a href="/CancellationRefundPolicy" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">CancellationRefundPolicy</a>
              </li>
              <li className="mb-1">
                <a href="/privacy-policy" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Privacy Policy</a>
              </li>
              <li className="mb-1">
                <a href="/terms-condition" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Terms and Conditions</a>
              </li>
              <li className="mb-1">
                <a href="/CancellationRefundPolicy" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">CancellationRefundPolicy</a>
              </li>
          {/*  <li className="mb-1">
                <a href="https://hirespoof.com/blog/" className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out">Blog</a>
              </li> */}  
            </ul>
          </div>
        </div>
        <div className="bottom-area">
          <div className="copyright">
            &copy; 2024 Hirespoof. All Right Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
