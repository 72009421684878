// src/components/RefundPolicy.js
import React from 'react';
import './RefundPolicy.css'; // Import the CSS file for styling
import Navbar from './Navbar';

const RefundPolicy = () => {
  return (
    <div className="refund-policy">
      <Navbar />
      <br />
      <h2>Refund Policy</h2>
      <p>
        Thank you for your purchase. Please note that all sales are final, and we do not offer refunds. We encourage you to carefully review your order before making a purchase.
      </p>
      <p>
        If you have any questions or need further assistance, please contact our support team at support@hirespoof.com.
      </p>
    </div>
  );
}

export default RefundPolicy;
