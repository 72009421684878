// src/components/ShippingPolicy.js
import React from 'react';
import './ShippingPolicy.css'; // Import the CSS file for styling
import Navbar from './Navbar';

const ShippingPolicy = () => {
  return (
    <div className="shipping-policy">
        <Navbar />  
        <br></br>
        <h2>Shipping Policy</h2>
        <p>
          We are glad you chose us for your purchase. The following conditions define our shipping policy for all orders and purchases made on the platform.
        </p>
        <h3>Shipping Location</h3>
        <p>We ship all orders from .</p>
        <h3>Order Tracking</h3>
        <p>
          You can view the status of your orders by visiting your account. The Order tab lets you view all your recent purchases. You can click on any current order to view their statuses.
        </p>
        <h3>Expected Delivery Time</h3>
        <p>
          Expected delivery times are displayed on the product page. You can enter your PIN to find out how many days your items may take to reach you. Choosing different shipping options may impact the delivery time. We generally take same day dispatch working days to process and ship your orders.
        </p>
        <p>
          You can also view the expected delivery time when you pay for your order and check out. However, high volume may impact shipping times and cause delays.
        </p>
        <h3>Contact</h3>
        <p>
          You can always contact us for any queries. We are available at support@hirespoof.com.
        </p>
        <h3>Payment</h3>
        <p>
          We believe in flexibility and let you pay using several methods. You can pick your preferred method while paying for your order during checking out. We support online payments only.
        </p>
        <p>
          Moreover, you can choose to pay in your preferred currencies. You will be able to change the currency to your choice during paying. We support the following currencies - All international currency payments are accepted.
        </p>
        <h3>Payment Plans</h3>
        <p>
          You can pay in affordable installments using our payment plans. The information about the plans is displayed when you check out. You will be able to compare your options and choose the right one.
        </p>
        <h3>Shipping Methods</h3>
        <p>
          We pride ourselves on making deliveries simple and hassle-free. You can select your preferred shipping method from several options when you check out.
        </p>
        <p>
          Our team takes same day dispatch business days to process your orders. A high volume of purchases may delay processing. You will always be able to track the status of your order from your account.
        </p>
        <h3>Shipping Costs</h3>
        <p>
          Shipping costs are calculated based on several factors. The considerations include the nature of the item, weight, size, distance, and so on. You may also be liable to pay taxes on your shipping as per applicable regulations.
        </p>
        <p>
          Our delivery partners always try to deliver your items at your convenience. You may even request a specific item to receive your order. Additionally, you may ask your delivery partner to leave your parcel with a neighbor.
        </p>
        <h3>Exchanges</h3>
        <p>
          Our team is happy to entertain exchanges if the product is damaged or doesn’t meet the description. You can request us to exchange your order using the steps below:
        </p>
        <h3>Returns</h3>
        <p>
          We are glad to help you return your product if it turns out to be damaged. You may also be able to request returns for items that don’t match the product description. Follow the steps below to file a return request:
        </p>
        <p>
          You can return or exchange your item for free if it is damaged or different from the description. However, in other cases, you may need to pay for the shipping charges.
        </p>
        <p>
          Additionally, we may take The timeline depends on the availability of the product requested business days to process returns and exchanges.
        </p>
        <p>
          Our team holds the ultimate right to decide whether to entertain your return or exchange request. We shall convey our decision and the cause regardless.
        </p>
        <h3>Misc</h3>
        <h4>Affiliate Program</h4>
        <p>We don’t have an affiliate program at the moment. You can always check back later.</p>
        <h4>Physical Store</h4>
        <p>We do not have a physical store. You can buy from us online for a great shopping experience.</p>
        <h4>Warranty</h4>
        <p>No, we don’t offer warranties.</p>
    </div>
  );
}

export default ShippingPolicy;
